define("discourse/plugins/discourse-user-card-badges/discourse/initializers/initialize-user-card-badges", ["exports", "discourse/lib/decorators", "discourse/lib/plugin-api"], function (_exports, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modifyUserCardContents(api) {
    api.modifyClass("component:user-card-contents", dt7948.p({
      pluginId: "discourse-user-card-badges",
      classNameBindings: ["hasCardBadgeImage"],
      hasCardBadgeImage(image) {
        return image && image.indexOf("fa-") !== 0;
      }
    }, [["method", "hasCardBadgeImage", [(0, _decorators.default)("user.card_badge.image")]]]));
  }
  var _default = _exports.default = {
    name: "user-card-badges",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.19", api => {
        modifyUserCardContents(api);
      });
    }
  };
});